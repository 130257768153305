.UploadPage {
  background-color: #F7F8FA;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .Home_background{
    margin-top: -20px;
    width: 100%;
    height: 241px;
    background-image: url('../../assets/images/background.png');
    background-size: 100% 100%;
    .container{
    padding: 0px 20px;
    min-width: 1340px;
    height: 95%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    h1{
      font-size: 36px;
      font-family: 'SourceHanSansSC-regular';
    }
    p{
      font-size: 16px;
      width: 690px;
      font-family: 'Roboto-regular';
    }
      }
    }
  .container {
    min-width: 1340px;
    height: 95%;
    border-radius: 10px;
    justify-content: center;
    align-items: flex-start;
    .Home{
      h1{
        font-size: 24px;
        margin: 0px 0px 30px;
        color: #71717A ;
        font-family: 'Roboto-regular';
      }
      .ant-upload.ant-upload-select{
        width: 100%;
      }
      .Alert{
        margin-top: 20px;
      }
      .LeftListButton[disabled]{
        color: #e8e8e896;
      }
      width: 100%;
      .ant-alert-content{
        div{
          font-family: 'Roboto-regular';
        }
      }
      .UploadPa{
        width: 100%;
        height: 370px;
        background-color: #fff !important;
        border: 1px solid #e4e4e7 ;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        img{
          padding: 20px 20px;
          background-color: rgba(243, 243, 243, 1);
          border-radius: 50%;
          margin-bottom: 20px;
        }
        span{
          font-family: 'Roboto-regular';
          font-size: 18px;
          color: #303030;
          &:hover{
            color:#208311;
          }
        }
      }
    }

    .LeftList{
      width: 430px;
      padding: 0px 20px 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-right: 20px;
      .LeftListBottom{
        width: 100%;
        margin-top: 40px;
        ul{
          width: 90%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .LeftListBottomLensdrive{
            font-family: 'Roboto';
            color: rgba(113, 113, 122, 1);           
          }
          li{
            color: #000;
            font-size: 14px;
            font-family: 'Roboto-regular';
            .Languag{
              div{
                font-family: 'Roboto-regular';
              }
            }
          }
        }
        .LeftListLanguage{
          display: flex;
          align-items: center;
        }
      }
    }

  }

}
@media (min-width: 660px)  and(max-width: 1350px) {
  .UploadPage{
    .Home_background {
      .container{
        min-width: 100%;
      }
    }
    .container{
      min-width: 100%;
      .Home{
        width: 70%;
      }
      .LeftList{
        width: 30%;
      }
    }
  } 
}
@media (min-width: 0px) and (max-width: 660px) {
  .UploadPage {
    .container{
      flex-direction: column;
      width: 100%;
      min-width: 100%;
      padding: 10px 20px;
      .Home{
        margin-top: 20px;
        h1{
          font-size: 16px;
          text-align: center;
        }
        .UploadPa{
          height: 180px;
          img{
            padding: 16px;
            height: 64px;
          }
          span{
            font-size: 14px;
          }
        }
      }
      .LeftList{
        width: 100%;
        padding: 0px 20px 10px;
      }
    }
    .head{
      .container{
        min-width: 100%;
        padding: 10px 20px;
        width: 100%;
      }
    }
    .Home_background {
      height: 184px;
      .container{
        padding: 10px 20px;
        min-width: 100%;
        padding: 10px 20px;
        width: 100%;
        h1{
          font-size: 24px;
        }
        p{
          width: 100%;
          font-size: 14px;
        }

      }
    }
  }
}