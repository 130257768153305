
@charset "utf-8";
@font-face {
    font-family: 'Muli';
    src: url('../../assets/iconfont/ifont.ttf');
    font-weight: 600;
}

@font-face {
  font-family: 'Roboto-regular';
  src: url('../../assets/fontLibrary/Roboto-regular.woff');
  font-weight: 600;
}
@font-face {
  font-family: 'SourceHanSansSC';
  src: url('../../assets/fontLibrary/SourceHanSansSC-regular.woff');
  font-weight: 600;
}

.Header{
  text-align: center;
  width: 100%;
  padding: 14px 0px;
  color: #fff;
  font-family: 'Roboto';
  font-size: 18px;
  background-image: url('../../assets/images/pointOut.png');
  .ant-btn-primary{
    background: #fff !important;
    color: #8776FA  !important;
    border: 0px !important;
    margin-left: 20px;
    span{
      font-family: 'Roboto';
    }
   
  }
}
.HeaderPage {
  width: 100%;
  // background-color: #F7F8FA;
  background-color: #F7F8FA;
  margin-bottom: 20px;

  .head {
    background: #fff;
    border-bottom: 1px solid #e4e4e7;
  .container {
    min-width: 1340px;
    padding: 10px 20px;
    border-radius: 0px 0px 0px 5px;
    justify-content: space-between;
    align-items: center;
    .head-left{
      display: flex;
      align-items: center;
      div {
        img{
          height: 48px;
        }
    }
    ul{
      display: flex;
      align-items: center;
      li{
        font-size: 18px;
        cursor: pointer;
        margin: 0px 30px;
        color: #86909c;
        &:hover{
          color: #9EA6B0;
        }
      }
      margin: 0px 0px 0px 30px;
    }
    } 
    .head-right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p{
        margin-bottom: 0px;
        margin-right: 30px;
        cursor: pointer;
      }
    }
}
}
}
@media (max-width:1350px) {
  .HeaderPage {
    .head {
    .container {
      min-width: 100%;
    }
  }
}
}