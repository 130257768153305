@font-face {
  font-family: "iconfont"; /* Project id 3813473 */
  src: url('iconfont.woff2?t=1673259498771') format('woff2'),
       url('iconfont.woff?t=1673259498771') format('woff'),
       url('iconfont.ttf?t=1673259498771') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-zhongyingwenqiehuan-yingwen:before {
  content: "\e617";
}

.icon-zhongyingwenqiehuan-zhongwen:before {
  content: "\e618";
}

.icon-guanbi:before {
  content: "\e60e";
}

.icon-copy-template:before {
  content: "\e616";
}

.icon-link-off:before {
  content: "\ea24";
}

.icon-shoujichongzhi:before {
  content: "\e6c8";
}

.icon-weikong:before {
  content: "\eda3";
}

